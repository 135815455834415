export enum ELocalStorage {
  ACCESS_TOKEN = 'token',
  USER = 'user',
  REDIRECT_URL = 'redirectUrl',
  UPCOMING_V3_BANNER = 'isUpcomingV3BannerViewed',
  V3_BANNER = 'isV3BannerViewed',
  PORTFOLIO_CSV_DATA = 'portfolioCsvData',
  NEW_RISK_SIMULATOR_BANNER = 'isNewRiskSimulatorBannerViewed',
  NEW_SEVERITY_LEVELS = 'areNewSeverityLevelsViewed',
}
